import { graphql, PageProps, Link } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import { Button } from "../components/Button";
import { layout } from "../constants";
import classnames from "classnames";
import parse from "html-react-parser";
import { RelativeTime } from "../components/RelativeTime";
import { nl } from "date-fns/locale";
import format from "date-fns/format";

const BlogIndex: React.VFC<
  PageProps<
    { allWpPost: { nodes: any[] } },
    { nextPagePath: string; previousPagePath: string }
  >
> = ({ data, pageContext }) => {
  const proseClass = "prose md:prose-lg";
  const posts = data.allWpPost.nodes;
  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <Layout headerVariation="small" title="Alle blog posts">
      <div className={classnames(layout.ySpacing)}>
        <div className={classnames(proseClass)}>
          <h1>Alle blog posts</h1>
        </div>

        <ol style={{ listStyle: `none` }}>
          {posts.map((post) => {
            const title = post.title;

            return (
              <li key={post.uri} className={proseClass}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="!mb-1">
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h2>
                    <time
                      className="text-sm text-mid"
                      itemProp="datePublished"
                      dateTime={post.date}
                    >
                      {format(new Date(post.date), "PPpp", { locale: nl })}
                    </time>
                  </header>
                  <section itemProp="description">
                    {parse(post.excerpt)}
                  </section>
                </article>
              </li>
            );
          })}
        </ol>

        {previousPagePath && (
          <>
            <Button to={previousPagePath}>Vorige pagina</Button>
            <br />
          </>
        )}
        {nextPagePath && <Button to={nextPagePath}>Volgende pagina</Button>}
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date
        title
        excerpt
      }
    }
  }
`;
